<template>
	<div>
		<b-table :selectable="true" @row-selected="pickCoin" striped small hover :items="theCoins" :fields="theFields">

			<template #cell(price)="data">
				<Price :price="data.item.price" :digits="data.item.digits" :price-date="data.item.price_date" />
			</template>

			<template #cell(rank)="data">
				<span :class="{ 'text-success': data.item.rank_delta > 0 }">{{ data.item.rank }}</span>
			</template>

			<template #cell(market_cap)="data">
				{{ data.item.market_cap | numeral(2, '0.0a') }}
			</template>

		</b-table>
	</div>
</template>

<script>

import numeral from 'numeral';
import _ from 'lodash';

export default {
	filters: {
		numeral: function(a, b, c) {
			return numeral(a / Math.pow(10,b)).format(c);
		}
	},
	props: [ 'coins', 'filter' ],
	methods: {
		pickCoin: function(coin) {
			this.$router.push({
				name: 'CoinDetail',
				params: { symbol: coin[0].symbol }
			});
		}
	},
	computed: {
		theCoins: function() {

			if(this.filter) return _.filter(this.coins, { symbol: this.filter });
			return this.coins;

		},
		theFields: function() {

			return [
				'coin_id',
				'symbol',
				{
					key: 'rank',
					sortable: true,
				},
				{
					key: 'price',
					sortable: true,
				},
				{
					key: 'market_cap',
					sortable: true,
				}
			]

		}
	}
};

</script>