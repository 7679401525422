<template>
	<span>
		{{dt}}
		<span class="small text-muted d-none d-sm-inline">{{time}}</span>
	</span>
</template>

<script>

import moment from 'moment';

export default {
	props: ['date'],
	computed: {
		dt: function() {
			return moment(this.date).format('DD/MM/YY');
		},
		time: function() {
			return moment(this.date).format('HH:MM');
		}
	}
};

</script>