<template>
	<div id="app">

		<TopBar></TopBar>

		<b-container class="pt-3">

			<router-view/>

		</b-container>

	</div>
</template>

<style lang="scss">

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
	padding-top: 4em;
}

</style>

<script>

import TopBar from '@/components/TopBar.vue';

export default {
	components: {
		TopBar
	}
};

</script>