<template>
	<div>
		<b-navbar fixed="top" toggleable="lg" type="dark" variant="dark">
			<b-container>
				
				<b-navbar-brand :to="{ name: 'Home' }">
					<img src="https://cryptounicorn.chimeralabs.net/media/1F984.svg" height="25" class="d-inline-block align-top" alt="Kitten">
					CryptoUnicorn
				</b-navbar-brand>

				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<b-nav-item active-class="active" :to="{ name: 'CoinsTabloid' }">Tabloid</b-nav-item>
					</b-navbar-nav>
					<b-navbar-nav v-if="!user">
						<b-nav-item active-class="active" :to="{ name: 'ProductsIndex' }">Prodotti</b-nav-item>
					</b-navbar-nav>
					<b-navbar-nav v-else>
						<b-nav-item active-class="active" :to="{ name: 'Observer' }">{{ $t('observers.title') }}</b-nav-item>
						<b-nav-item href="#" disabled>Last Minute</b-nav-item>
					</b-navbar-nav>

					<!-- Right aligned nav items -->
					<b-navbar-nav class="ml-auto">
						<!--<b-nav-form>
							<b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
							<b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
						</b-nav-form>-->

						<b-nav-item-dropdown v-if="user" right>
							<template #button-content>
								<b-icon icon="person-circle"></b-icon>
							</template>
							<b-dropdown-item :to="{ name: 'MyAccount' }">Profilo</b-dropdown-item>
							<b-dropdown-item @click.prevent="$root.UserLogout">Esci</b-dropdown-item>
						</b-nav-item-dropdown>
						<b-nav-item v-else :to="{ name: 'Login' }">Entra</b-nav-item>

					</b-navbar-nav>
				</b-collapse>
			</b-container>
		</b-navbar>
	</div>
</template>

<script>

export default {

};

</script>