import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/products',
		name: 'Products',
		component: function() {
			return import(/* webpackChunkName: "about" */ '../views/Products.vue')
		},
		children: [
			{ 
				name: 'ProductsIndex', 
				path: '/', 
				component: function() {
					return import(/* webpackChunkName: "about" */ '../pages/Products/Index.vue')
				}
			},
			{ 
				name: 'ProductsObserver', 
				path: 'observatory',
				meta: {
					navigation: [
						{ text: 'products.title', to: { name: 'ProductsIndex' } },
						{ text: 'observers.title' },
					]
				},
				component: function() {
					return import(/* webpackChunkName: "about" */ '../pages/Products/Observer.vue')
				}
			},
		]
	},
	{
		path: '/coin/:symbol',
		name: 'CoinDetail',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../pages/Coins/Single.vue')
		}
	},
	{
		path: '/tabloid',
		name: 'CoinsTabloid',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../pages/Coins/Tabloid.vue')
		}
	},
	{
		path: '/login',
		name: 'Login',
		component: function () {
			return import(/* webpackChunkName: "about" */ '../pages/Account/Login.vue')
		}
	},
	{
		path: '/observatory',
		meta: {
			auth: true
		},
		component: function() {
			return import(/* webpackChunkName: "about" */ '../views/Observatory.vue')
		},
		children: [
			{ name: 'Observer', path: '/', component: function() {
				return import(/* webpackChunkName: "about" */ '../pages/Observer/Index.vue')
			} },
			{ 
				name: 'ObserverDetail', 
				path: 'mission/:observer_id',
				meta: {
					navigation: [
						{ text: 'products.title', to: { name: 'Dashboard' } },
						{ text: 'observers.title', to: { name: 'Observer' } },
						{ text: 'observers.detail' },
					]
				},
				component: function() {
					return import(/* webpackChunkName: "about" */ '../pages/Observer/Detail.vue')
				}
			},
		]
	},
	{
		path: '/account',
		meta: {
			auth: true
		},
		component: function() {
			return import(/* webpackChunkName: "about" */ '../views/Account.vue')
		},
		children: [
			{ name: 'MyAccount', path: '/', component: function() {
				return import(/* webpackChunkName: "about" */ '../pages/Account/Index.vue')
			} }
		]
	}
]

const router = new VueRouter({
	routes
})

export default router
