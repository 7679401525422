<template>
	<div class="home">

		<b-skeleton-wrapper :loading="!data.coins">
			<template #loading>
				<p class="lead">Attendi Caricamento</p>
				<b-card>
					<b-skeleton width="85%"></b-skeleton>
					<b-skeleton width="55%"></b-skeleton>
					<b-skeleton width="70%"></b-skeleton>
				</b-card>
			</template>

			<div>
				<CoinsTable :coins="data.coins"></CoinsTable>
			</div>
		</b-skeleton-wrapper>
	</div>
</template>

<script>

// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import CoinsTable from '@/components/Coins/CoinsTable.vue'

export default {
	name: 'Home',
	components: {
		HelloWorld,
		CoinsTable
	},
	data: function() {
		return {
			data: false
		}
	},
	created: function() {
		this.fetch();
	},
	methods: {
		fetch: function() {
			this.$http.post('https://cryptounicorn.chimeralabs.net/api/coins.all').then(function(data) {
				this.data = data.data;
			});
		}
	}
};
</script>
