<template>
	<span v-b-popover.hover.top="priceDate">
		<strong>{{displayPrice.integers}}.</strong>{{displayPrice.firstHalf}}<small class="text-muted" v-if="bits && displayPrice.secondHalfDecimal != 0">{{ displayPrice.secondHalf }}</small>
	</span>
</template>

<script>

import numeral from 'numeral';

export default {

	props: {
		price: { default: 0 },
		digits: { default: 8 },
		priceDate: { default: false },
		bits: { default: true }
	},
	computed: {
		displayPrice: function() {

			var s = Math.abs(this.digits);

			var digitPattern = '', u = 0;
			for(u = 0; u < s; u++)
				digitPattern += '0';

			if(this.digits > 0)
				var string = numeral(parseInt(this.price) / Math.pow(10, this.digits)).format('0,0.' + digitPattern);
			else
				var string = numeral(parseInt(this.price * Math.pow(10,s)) / Math.pow(10, s)).format('0,0.' + digitPattern);

			var integers = string.split('.')[0];
			var decimals = string.split('.')[1];

			return {
				integers,
				decimals,
				firstHalf: decimals.substr(0,s/2),
				secondHalf: decimals.substr(s/2,s),
				secondHalfDecimal: parseInt(decimals.substr(s/2,s)),
			}

		}
	}

};

</script>