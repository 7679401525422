import Vue from 'vue'
import './plugins/bootstrap-vue'
import './plugins/vue-resource'
import './plugins/chart'
import './plugins/currency-input'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import moment from 'moment';
import numeral from 'numeral';

Vue.config.productionTip = false;

import Price from '@/components/Price.vue';
import DateDisplay from '@/components/Date.vue';
import i18n from './i18n'

Vue.component('Price', Price);
Vue.component('Date', DateDisplay);


var cu = {
	data: {},
	environment: false,
	errors: [],
	uid: 'cu_',
	s: function(k, value) {
		if(this.data[k] == undefined)
			Vue.set(this.data, k, false);
		this.data[k] = value;
		this.localStore();
		return this.data[k];
	},
	u: function(k) {
		localStorage.removeItem(this.uid + k);
		delete this.data[k];
	},
	g: function(k) {
		return this.data[k];
	},
	user: function() {
		return this.g('jwt');
	},
	localStore: function() {
		for(var k in this.data)
			window.localStorage.setItem(this.uid + k, btoa(JSON.stringify(this.data[k])));
	},
	localLoad: function() {
		var storage = window.localStorage;
		for(var a in storage) {
			if(typeof storage[a] != 'function') {
				if( a.indexOf(this.uid) != -1 ) {
					var innerKey = a.replace(this.uid, '');
					this.s(innerKey, JSON.parse(atob(storage[a])));
				}
			}
		}
	},
	destroy: function() {
		_.each(_.keys(this.data), function(a) {
			localStorage.removeItem(this.uid + a);
		}.bind(this));
		return true;
	},
	init: function() {
		this.localLoad();
	},
	bearer: function() {
		return btoa(btoa(this.g('bearer')))
	}
}

cu.init();

// Authorization Checker
Vue.http.interceptors.push(function(request, next) {
	
	this.$root.loading = true;
	
	if(cu.user())
		Vue.http.headers.common['Authorization'] = 'Bearer ' + cu.user();
	else
		Vue.http.headers.common['Authorization'] = '';

	next();

	// Watcher for `net-wait` status.
	return function(response) {
		this.$root.loading = false;
	};

});

router.beforeEach(function(to, from, next) {

	var canGo = true;
	var amoutOfMatched = to.matched.length;

	var unifiedMeta = _.map(to.matched, 'meta');
	var meta = {};
	_.each(unifiedMeta, function(i) {
		_.extend(meta, i);
	});

	if(meta.auth) {
		if(!cu.user()) {
			canGo = false;
			next({ name: 'Login', query: { redirectTo: window.location.hash.replace('#', '') } });
		}
	}

	if(canGo == true)
		next();

});


Vue.mixin({
	filters: {
		date: function(d) {
			return moment(d).format('DD/MM/YY HH:mm')
		},
		time: function(d) {
			return moment(d).format('HH:mm')
		},
		percent: function(d) {
			return numeral(d).format('+0.00%')
		},
	},
	computed: {
		navigation: function() {

			var nav = _.cloneDeep(this.$route.meta.navigation);

			return _.map(nav, function(a) {
				a.text = this.$t(a.text);
				return a;
			}.bind(this));
			
		},
		uri: function() {
			return 'https://cryptounicorn.chimeralabs.net/api/';
		},
		unicorn: function() {
			return this.$root.cu;
		},
		env: function() {
			return this.$root.environment;
		},
		user: function() {
			if(this.env) return this.$root.environment.user;
		}
	},
	methods: {
		marked: function(t) {
			return marked(t);
		}
	}
});


new Vue({
    data: {
		environment: false,
		cu: cu,
	},

    methods: {
		LoadEnv: function(then) {

			this.$http.post(this.uri + 'users.whoami').then(function(data) {

				this.environment = data.data;
				this.cu.environment = this.environment;

				if(then) {
					then();
				}

			}, function() {
				this.UserLogout();
			});

		},
		UserLogout: function() {

			this.cu.destroy();

			_.delay(function() {
				location.reload();
			}.bind(this), 100);

		}
	},

    created: function() {

		if(cu.user())
			this.LoadEnv();

		this.$on('envRefresh', function() {
			this.LoadEnv();
		});

	},

    router,
    i18n,
    render: function (h) { return h(App) }
}).$mount('#app')
