<template>
	<div class="hello">
		<pre>{{f}}</pre>
		<h1>{{ '2021-02-25' | moment("dddd, MMMM Do YYYY") }}</h1>
	</div>
</template>

<script>

export default {
	name: 'HelloWorld',
	props: {
		msg: String
	},
	computed: {
		f: function() {
			return this.infos;
		}
	},
	data: function() {
		return {
			infos: [
				{ price: 0 },
				{ price: 100 },
				{ price: 10 }
			]
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
	margin: 40px 0 0;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}
</style>
